export default
{
  menuEmployee: [

    {
      title: 'Home',
      route: 'home',
      icon: 'HomeIcon',
    },
    {
      title: 'Quản lý nhân viên',
      route: 'manager-employee',
      icon: 'UserIcon',
    },
    {
      title: 'Quản lý tổ chức',
      icon: 'LayersIcon',
      children: [
        {
          title: 'Quản lý phòng ban',
          route: 'manager-department',
          icon: 'LayersIcon',
        },
        {
          title: 'Quản lý khối',
          route: 'manager-school-block',
          icon: 'LayersIcon',
        },
        {
          title: 'Quản lý tổ',
          route: 'manager-school-group',
          icon: 'LayersIcon',
        },
      ],
    },
    {
      title: 'Lịch sử nâng lương',
      route: 'salary-employee',
      icon: 'BarChartIcon',
    },
  ],
  menuStudent: [
    {
      title: 'Home',
      route: 'home',
      icon: 'HomeIcon',
    },
    {
      title: 'Hồ sơ tuyển sinh',
      route: 'student-management',
      icon: 'FileTextIcon',
    },
    {
      title: 'CT Khuyến mãi',
      route: 'promotion',
      icon: 'StarIcon',
    },
  ],
  menuAll: [
    {
      title: 'Home',
      route: 'home',
      icon: 'HomeIcon',
    },
    {
      title: 'Quản lý tài khoản',
      route: 'manager-account',
      icon: 'SettingsIcon',
    },
    {
      title: 'Ca làm việc',
      route: 'manage-work-shift-branch',
      icon: 'UserIcon',
    },
    {
      title: 'Quản lý nhân viên',
      route: 'manager-employee',
      icon: 'UserIcon',
    },
    {
      title: 'Hồ sơ tuyển sinh',
      route: 'student-management',
      icon: 'FileTextIcon',
    },
    {
      title: 'Quản lý tổ chức',
      icon: 'LayersIcon',
      children: [
        {
          title: 'Quản lý phòng ban',
          route: 'manager-department',
          icon: 'LayersIcon',
        },
        {
          title: 'Quản lý khối',
          route: 'manager-school-block',
          icon: 'LayersIcon',
        },
        {
          title: 'Quản lý tổ',
          route: 'manager-school-group',
          icon: 'LayersIcon',
        },
      ],
    },
    {
      title: 'Lịch sử nâng lương',
      route: 'salary-employee',
      icon: 'BarChartIcon',
    },
    {
      title: 'CT Khuyến mãi',
      route: 'promotion',
      icon: 'StarIcon',
    },
    {
      title: 'Tài sản',
      icon: 'PackageIcon',
      children: [
        {
          title: 'Quản lý tài sản',
          route: 'manager-asset',
        },
        {
          title: 'Cấp phát và thu hồi',
          route: 'allocation-recall-asset',
        },
        {
          title: 'Lịch sử tài sản',
          route: 'history-asset',
        },
      ],
    },
    {
      title: 'Vật dụng',
      icon: 'CodepenIcon',
      children: [
        {
          title: 'Quản lý vật dụng',
          route: 'manager-item',
        },
        {
          title: 'Cấp phát và thu hồi',
          route: 'allocation-recall-device',
        },
        {
          title: 'Lịch sử vật dụng',
          route: 'history-item',
        },
      ],
    },
  ],
  menuPropertySupplies: [
    {
      title: 'Tài sản',
      icon: 'PackageIcon',
      children: [
        {
          title: 'Quản lý tài sản',
          route: 'manager-asset',
        },
        {
          title: 'Cấp phát và thu hồi',
          route: 'allocation-recall-asset',
        },
        {
          title: 'Lịch sử tài sản',
          route: 'history-asset',
        },
      ],
    },
    {
      title: 'Vật dụng',
      icon: 'CodepenIcon',
      children: [
        {
          title: 'Quản lý vật dụng',
          route: 'manager-item',
        },
        {
          title: 'Cấp phát và thu hồi',
          route: 'allocation-recall-device',
        },
        {
          title: 'Lịch sử vật dụng',
          route: 'history-item',
        },
      ],
    },
  ],
}
